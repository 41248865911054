import React from "react";
import { graphql } from "gatsby";
import { ArticleCards } from "~/molecules/ArticleCards";
import { Layout } from "~/templates/Layout";
import { PATH } from "~/constant/path";
import { ArticleHeader } from "~/molecules/ArticleHeader";
import { BaseArticleBody } from "~/molecules/BaseArticleBody";
import { ArticleFooter } from "~/molecules/ArticleFooter";
import { RecommendedArticles } from "~/molecules/RecommendedArticles";
import { ArticleListSection } from "~/molecules/ArticleListSection";
import { Container } from "~/atoms/Container";
import { ArticleContent } from "~/organisms/ArticleContent";
import { ArticleSidebar } from "~/organisms/ArticleSidebar";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

interface Props {
  pageContext: {
    awardYear: number;
    contentfulId: string;
    regex: string;
  };
  path: string;
  data: {
    site: GatsbyTypes.Site;
    awardArticle: GatsbyTypes.ContentfulReport;
    allReports: GatsbyTypes.ContentfulReportConnection;
    recommendReports: GatsbyTypes.ContentfulReportConnection;
    recommendCases: GatsbyTypes.ContentfulCaseConnection;
  };
}

const idsForCanonical = [
  {
    originId: `cQ6vqoyHkn76QERq5AyjU`,
    targetId: `5swfIV0c3WWN9bW0wWGsEa`,
  },
  {
    originId: `2DsdlWqlYrWxW6tUnaLkUF`,
    targetId: `vyOdzRf05mmPsjcUis5hP`,
  },
];

const ArticleAward: React.FC<Props> = ({ path, pageContext, data }) => {
  const { awardArticle, site } = data;
  const requiredCanonical = idsForCanonical.filter((ids) => ids.originId === pageContext.contentfulId);
  const canonicalUrl =
    requiredCanonical.length === 1
      ? `${site.siteMetadata?.siteUrl}/cases/${requiredCanonical[0].targetId}/`
      : undefined;

  const breadCrumbItems = [
    PATH.TOP,
    PATH.EVENTS,
    PATH.EVENTS_AWARD,
    PATH[`EVENTS_AWARD_${awardArticle.awardYear}`],
    {
      name: awardArticle.title,
    },
  ];

  const ogpDescription = awardArticle.ogpDescription && awardArticle.ogpDescription.internal.content;
  const awardArticles = data.allReports.edges.map((edge) => {
    return {
      node: edge.node,
      href: `/events/award/interview${edge.node.awardYear}/${edge.node.contentful_id}`,
    };
  });

  const recommendCases = data.recommendCases.edges.map((edge) => {
    return {
      node: edge.node,
      href: `/cases/${edge.node.contentful_id}`,
    };
  });

  const recommendReports = data.recommendReports.edges.map((edge) => {
    return {
      node: edge.node,
      href: `/events/${edge.node.eventCategory?.slug}/${
        (edge.node.eventCategory?.slug === "award" && `interview${edge.node.awardYear}/`) || ``
      }${edge.node.contentful_id}`,
    };
  });

  return (
    <>
      <style jsx>{`
        .articleWrapper {
          background-color: ${colors.creamGray};
        }
        .articleWrapper :global(.container) {
          padding: 30px 0 50px;
        }
        @media (${breakPoints.sp}) {
          .articleWrapper :global(.container) {
            flex-direction: column;
            padding: 0 0 50px;
          }
        }
      `}</style>
      <Layout
        title={awardArticle.title}
        ogpDescription={ogpDescription}
        ogpImageUrl={awardArticle.ogpImage && `https:${awardArticle?.ogpImage?.fluid?.src}`}
        ogpTitle={awardArticle.ogpTitle}
        canonicalUrl={canonicalUrl}
        breadCrumbItems={breadCrumbItems}
        breadCrumbBgColor={colors.creamGray2}
      >
        <div className="articleWrapper">
          <Container>
            <ArticleContent>
              <ArticleHeader
                categoryName={PATH[`EVENTS_AWARD_${awardArticle.awardYear}`].name}
                title={awardArticle.title}
                date={awardArticle.date}
                companyName={awardArticle.companyName}
              />
              <BaseArticleBody
                content={awardArticle.content}
                ogpImageSrc={awardArticle?.ogpImage?.fluid?.src}
                ogpImageAspect={awardArticle?.ogpImage?.fluid?.aspectRatio}
              />
              <ArticleFooter url={path} title={awardArticle.title} ogpTitle={awardArticle.ogpTitle} />
            </ArticleContent>
            <ArticleSidebar articles={awardArticles} category={PATH[`EVENTS_AWARD_${awardArticle.awardYear}`].name} />
          </Container>
        </div>
        <ArticleListSection title={PATH[`EVENTS_AWARD_${awardArticle.awardYear}`].name}>
          <ArticleCards articles={awardArticles.slice(0, 3)} prefix="AwardArticles" />
        </ArticleListSection>
        <ArticleListSection title="こちらもおすすめ">
          <RecommendedArticles articles={[...recommendCases, ...recommendReports]} />
        </ArticleListSection>
      </Layout>
    </>
  );
};

export default ArticleAward;

export const articleAwardQuery = graphql`
  query ArticleAward($contentfulId: String, $regex: String, $awardYear: Int) {
    site: site {
      siteMetadata {
        siteUrl
      }
    }
    awardArticle: contentfulReport(contentful_id: { eq: $contentfulId }) {
      contentful_id
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            file {
              url
            }
          }
        }
      }
      title
      awardYear
      companyName
      date
      eventCategory {
        slug
      }
      ogpDescription {
        internal {
          content
        }
      }
      ogpImage {
        fluid(maxWidth: 900, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      ogpTitle
    }
    allReports: allContentfulReport(
      filter: {
        contentful_id: { ne: $contentfulId }
        eventCategory: { slug: { eq: "award" } }
        awardYear: { eq: $awardYear }
      }
      limit: 4
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          contentful_id
          title
          ogpImage {
            fluid {
              src
              aspectRatio
            }
          }
          companyName
          date
          awardYear
        }
      }
    }
    recommendReports: allContentfulReport(
      filter: { contentful_id: { ne: $contentfulId }, tag: { elemMatch: { slug: { regex: $regex } } } }
    ) {
      edges {
        node {
          contentful_id
          title
          ogpImage {
            fluid {
              src
              aspectRatio
            }
          }
          companyName
          awardYear
          eventCategory {
            slug
          }
          date
        }
      }
    }
    recommendCases: allContentfulCase(
      filter: { contentful_id: { ne: $contentfulId }, tag: { elemMatch: { slug: { regex: $regex } } } }
    ) {
      edges {
        node {
          contentful_id
          title
          ogpImage {
            fluid {
              src
              aspectRatio
            }
          }
          company {
            name
          }
          date
        }
      }
    }
  }
`;
